export const useSessionData = () => useState('sessionData', () => reactive({
    eventEnded: false,
    contingentFull: false,
    isPageAccessAllowed: false,
})); // useSessionData ist ein ref!

// // MUSS auf jeder Seite/Komponente definiert sein
// // der Name sessionData kann auf jeder Seite/Komponente identisch sein
// const sessionData = useSessionData()

// // Session Wert definieren
// sessionData.value.luftballons = '99'
// sessionData.value.autos = '23'
// ....

// // Session Wert lesen
// const variable = sessionData.value.luftballons
// const variable = sessionData.value.autos
// ....
